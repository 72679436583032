import BrandName from '~/components/Logo'

const PageLoading = () => {
    return (
        <div className="w-full h-screen flex justify-center">
            <div className="pt-[38.2vh]"><BrandName /></div>
        </div>
    )
}

export default PageLoading
